<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template functional>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <p>
      <span>Sistema de Gerenciamento de Projetos CNC &copy;</span>
      <span>{{ new Date().getFullYear() }} </span>
      Ambev - Projeto Sirius
      <span class="hidden sm:inline-block">, Todos os direitos reservados</span>
    </p>
    <p>
      <img
        src="../../assets/images/logo/logo_mobfeel.png"
        width="40px"
        height="40px"
        class="space"
      />
      <img
        src="../../assets/images/logo/logo.jpeg"
        width="40px"
        height="40px"
        class="space"
      />
    </p>
  </footer>
</template>

<script>
export default {
  name: "the-footer",
  props: {
    classes: {
      type: String,
    },
  },
};
</script>
<style>
.space {
  margin-left: 10px;
}
</style>

