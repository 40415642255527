/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    header: "GESTÃO DE PROJETOS E PEDIDOS",
    icon: "CoffeeIcon",
    items: [
      {
        url: "/admin/addrequest",
        name: "Nova requisição",
        slug: "addrequest",
        icon: "CalendarIcon",
        rule: "isRequester",
      },
      {
        url: "/admin/myorders",
        name: "Meus Pedidos",
        slug: "myorders",
        icon: "CalendarIcon",
        rule: "isRequester",
      },
      {
        url: "/admin/projects",
        name: "Catálogo de Peças",
        slug: "projects",
        icon: "CalendarIcon",
        rule: "isRequester",
      },
      {
        url: "/admin/pendingOrders",
        name: "Pedidos Pendentes",
        slug: "pendingOrders",
        icon: "CalendarIcon",
        rule: "isSupervisor",
      },
      {
        url: "/admin/kanban",
        name: "Kanban",
        slug: "kanban",
        icon: "CalendarIcon",
        rule: "isLogged",
      },
      {
        url: "/admin/users",
        name: "Usuários",
        slug: "users",
        icon: "CalendarIcon",
        rule: "isAdmin",
      },
    ],
  },
];
